<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :buttonShow="false"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :rules="rules"
      @selectChange="getSelectChangeInfo"
      @remote="getSelectChangeInfo"
    >
      <!-- <div class="search" slot="subSlot">
        <el-input
          placeholder="根据名称查询"
          v-model="searchQuery.siteName"
          class="searchInput"
          size="small"
        >
          <template slot="prepend">名称</template>
        </el-input>

        <el-input
          placeholder="根据电报码查询"
          v-model="searchQuery.siteCode"
          class="searchInput"
          size="small"
        >
          <template slot="prepend">电报码</template>
        </el-input>
      </div> -->
    </app-list>

    <el-dialog
      title="设置自提时段"
      :visible.sync="digShow"
      width="800px"
      @close="digShow = false"
    >
      <div
        class="timeItem m-b-10"
        v-for="(item, index) in timeForm.timeItem"
        :key="index"
      >
        <span class="commonLabel">自提时段{{ index + 1 }}</span>
        <el-time-picker
          is-range
          v-model="timeForm.timeItem[index]"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          size="small"
          value-format="HH:mm:ss"
          format="HH:mm:ss"
        >
        </el-time-picker>
        <el-button type="" @click="removeItem(index)" size="small"
          >删除</el-button
        >
      </div>
      <span slot="footer">
        <el-button type="primary" @click="addArrItem">新增一组</el-button>
        <el-button type="primary" @click="handleTakeSub">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api';

export default {
  data() {
    let self = this;
    return {
      digShow: false,
      timeForm: { storeId: '', timeItem: [''] },

      pswDialogVisible: false,
      remote: api.common,
      apiName: '/store',
      searchVal: '',
      bntLoading: false,
      searchQuery: { siteName: '', siteCode: '', timer: 0 },
      rules: {
        //传递给APPlist的表单验证
      },
      props: [
        {
          label: '店铺名',
          prop: 'storeName',
          align: 'center'
        },

        {
          label: 'logo',
          prop: 'logo',
          align: 'center',
          type: 'html',
          formatter: function(row) {
            const prefix =
              process.env.NODE_ENV == 'development'
                ? '<img src=test/'
                : '<img src=api/';
            return prefix + row.logo + " class='tablePic'/>";
          }
        },

        {
          label: '店铺掌柜',
          prop: 'userName',
          align: 'center'
        },
        {
          label: '联系电话',
          prop: 'phone',
          align: 'center'
        },

        {
          label: '店铺地址',
          prop: 'address',
          align: 'center'
        },
        {
          label: '店铺介绍',
          prop: 'description',
          align: 'center'
        },
        {
          label: '邮箱',
          prop: 'mail',
          align: 'center'
        },
        {
          label: '经度',
          prop: 'lng',
          align: 'center'
        },
        {
          label: '纬度',
          prop: 'lat',
          align: 'center'
        },
        {
          label: '操作',
          prop: 'qrcode',
          align: 'center',
          width: 250,
          type: 'operationLink',
          formatter: function(row) {
            const buttonList = [
              {
                text: '时段配置',
                size: 'mini',
                data: row,
                show: true,
                icon: 'el-icon-user',
                type: 'success',

                handler: function() {
                  self.handleTakeTime(row);
                }
              },
              {
                text: '编辑',
                size: 'mini',
                data: row,
                show: true,
                icon: 'el-icon-edit',
                type: 'primary',
                handlerType: 'update',
                handler: function() {}
              },
              {
                text: '删除',
                size: 'mini',
                data: row,
                show: true,
                icon: 'el-icon-delete',
                type: 'danger',

                handler: function() {
                  self.handleDel(row.id);
                }
              }
            ];
            return buttonList;
          }
        }
      ],
      formProps: [
        {
          label: '店铺名',
          type: 'input',
          prop: 'storeName'
        },
        {
          label: '店铺掌柜',
          type: 'input',
          prop: 'userinfoId',
          placeholder: '请填写用户ID'
        },
        {
          label: '联系电话',
          type: 'input',
          prop: 'phone'
        },

        {
          label: '城市',
          type: 'input',
          prop: 'cityName',
          default: '南宁市'
        },
        {
          label: '区域',
          type: 'select',
          prop: 'countyName',
          default: '西乡塘区',
          selectData: [
            { label: '兴宁区', value: '兴宁区' },
            { label: '青秀区', value: '青秀区' },
            { label: '江南区', value: '江南区' },
            { label: '西乡塘区', value: '西乡塘区' },
            { label: '良庆区', value: '良庆区' },
            { label: '邕宁区', value: '邕宁区' },
            { label: '武鸣区', value: '武鸣区' }
          ]
        },

        {
          label: '详细地址',
          type: 'input',
          prop: 'fromUsernote'
        },

        {
          label: '经度',
          type: 'input',
          prop: 'lng'
        },
        {
          label: '纬度',
          type: 'input',
          prop: 'lat'
        },

        {
          label: '店铺描述',
          type: 'input',
          prop: 'description'
        },

        {
          label: 'Logo',
          type: 'upload',
          prop: 'logo'
        }
      ]
    };
  },
  created() {},
  mounted() {
    this.getUserList();
  },
  methods: {
    getSelectChangeInfo(e, info) {
      console.log(e, info);
    },

    addArrItem() {
      this.timeForm.timeItem.push('');
    },
    async handleTakeSub() {
      if (this.timeForm.timeItem.length == 0) {
        this.$message.error('请至少添加一个时间段');
        return;
      }

      let isEmpty = this.timeForm.timeItem.findIndex(item => {
        return item == '';
      });
      if (isEmpty !== -1) {
        this.$message.error('请勿添加空时段');
        return;
      }
      let itemList = [];
      this.timeForm.timeItem.forEach(element => {
        itemList.push({ startTime: element[0], endTime: element[1] });
      });

      let res = await api.common.commonPost({
        apiName: '/storeTakeTime/update',
        storeId: this.timeForm.storeId,
        itemList: itemList
      });
      if (res.code == 200) {
        this.$message.success('设置成功');
        this.digShow = false;
        this.searchQuery.timer = new Date().getTime();
      }
    },

    removeItem(index) {
      this.timeForm.timeItem.splice(index, 1);
    },

    handleTakeTime(row) {
      console.log(row);
      this.timeForm.timeItem = [];
      row.timeList.forEach(item => {
        this.timeForm.timeItem.push([item.startTime, item.endTime]);
      });
      this.timeForm.storeId = row.id;

      this.digShow = true;
    },
    async handleDel(id) {
      this.$confirm('此操作将删除店铺及商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await api.common.commonPost({
            apiName: '/store/remove',
            ids: [id]
          });
          if (res.code == 200) {
            this.$message.success(res.message);
            this.searchQuery.timer = new Date().getTime();
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    async getUserList() {
      let res = await api.common.commonGet({ apiName: '/user' });
      let array = [];
      res.data.data.forEach(element => {
        array.push({
          value: element.id,
          label: element.name
        });
      });
      // this.formProps[1].selectData = array;
    }
  }
};
</script>

<style lang="scss" scoped>
.changeCon p {
  color: #999;
}

.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}

.search {
  display: flex;
}

.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
